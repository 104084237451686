import Home from "./page/Home";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle"
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Contact from "./page/Contact";
import Notfound from "./page/Notfound";
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<Notfound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
