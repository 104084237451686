import React, { useState } from 'react'

function Home() {
    const [menuactive,setmenuactive] = useState(false)
    const activebtn = () =>{
        setmenuactive(!menuactive)
    }
    return (
        <div className='home'>
            <div className="navbar bg-dark bg-opacity-75">
                <div className="conitainer">
                    <a href='/' className='brand'>Mebera</a>
                    <div className='links'>
                        <a href="#haqqımızda">Haqqımızda</a>
                        <a href="#xidmətlərimiz">Xidmətlətimiz</a>
                    </div>
                    <div className="menubtn" onClick={()=>activebtn()}>
                        <img src="icons/menubtn.svg" alt="" />
                    </div>
                </div>
            </div>
            {menuactive? (
                <div className="modall bg-dark">
                    <a href='/' className="brand">Mebera</a>
                    <div className="close" onClick={()=>{activebtn()}}>
                        <img src="icons/close.svg" alt="" />
                    </div>
                    <div className="links">
                        <a onClick={()=>{activebtn()}} href="#haqqımızda">Haqqımızda</a>
                        <a onClick={()=>{activebtn()}} href="#xidmətlərimiz">Xidmətlərimiz</a>
                    </div>
                </div>

            ):(null)}
            <div className="img shadow-lg">
                <div className="slog">
                    <div className="mainslog">Yaradıcı Ev Simfoniyası Sənin Mebelində</div>
                    <div className="subslog mt-2">Mebera İlə Eviniz Gözəl Olsun</div>
                    <a href="https://shop.mebera.az" className='shopnow mt-4 rounded bg-light bg-opacity-25' target='_blank'>Indi Al</a>
                </div>
            </div>
            <div className="stats">
                <div className="stat">
                    <div className="num">23</div>
                    <div className="text">İllik Təcrübə</div>
                </div>
                <div className='line'></div>
                <div className="stat">
                    <div className="num">10k +</div>
                    <div className="text">Mebel Satışı</div>
                </div>
                <div className='line'></div>

                <div className="stat">
                    <div className="num">260 +</div>
                    <div className="text">Mebel Çeşidi</div>
                </div>
                <div className='line'></div>

                <div className="stat">
                    <div className="num">300 +</div>
                    <div className="text">İllik Müştəri</div>
                </div>
            </div>
            <div id="haqqımızda">
                <div className="about-text">
                    <div className="about-head">Haqqımızda</div>
                    <div className="about-body">Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure itaque debitis ipsam natus explicabo ad placeat repudiandae necessitatibus neque ex culpa rerum harum non blanditiis autem adipisci quia, corporis aliquid!</div>
                </div>
                <div className="about-img">
                    <img src="/images/about.png" alt="about" />
                </div>
            </div>
            <div id="xidmətlərimiz">
                <div className="img">
                    <img src="images/info.png" alt="info" />
                </div>
                <div className="info">
                    <div className="infohead">
                        Xidmətlərimiz
                    </div>
                    <div className="infobody">
                        Biz Mebel Sektorunda Olan Yataq, Qonaq, Ofis, Yumşaq və Mətbəx Mebelləri Üzrə Aşağıdakı Xidmətləri Göstəririk
                    </div>
                    <div className="subhead">
                        <img src="icons/checked.svg" alt="" />
                        <div className="subheadtext">Sifariş</div>
                    </div>
                    <div className="subtext">Sifarişlə İstənilən Növ Mebelin Yüksək Keyfiyyətdə Hazırlanması</div>
                    <div className="subhead">
                        <img src="icons/checked.svg" alt="" />
                        <div className="subheadtext">Təmir</div>
                    </div>
                    <div className="subtext">İstənilən Növ Mebellərin Yüksək Keyfiyyətdə Təmiri</div>
                </div>
            </div>
        </div>
    )
}

export default Home