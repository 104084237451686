import React, { useState } from 'react'

function Notfound() {
  const [formData, setFormData] = useState({
    name: '',
    email: ''
  });
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };
  const handleSubmit  = async (e)=>{
    e.preventDefault()
    try {
      const response = await fetch('https://server.mebera.az/submit-data', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      if (response.ok) {
        console.log('Data submitted successfully');
      } else {
        console.error('Error submitting data');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }
  return (
    <div>
      <form onSubmit={handleSubmit }>
        <input type="text" name='name' placeholder='name' value={formData.name} onChange={handleChange} />
        <input type="email" name='email' placeholder='email' value={formData.email} onChange={handleChange} />
        <button type='submit'>Submit</button>
      </form>
    </div>
  )
}

export default Notfound